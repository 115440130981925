/*
Редактирование ОДНОЙ строки в списочных представлениях
*/

const state = () => ({
  rowEditing: null,
  rowEditingIndex: -1,
  rowEditingItems: [],
  // Запуск редакатирование строки из вне по ид
  rowEditingIdNew: -1,
});

const mutations = {
  setEdited(state, { rowEditing, rowEditingIndex }) {
    state.rowEditing = rowEditing;
    state.rowEditingIndex = rowEditingIndex;
  },
  setIdEdit(state, id) {
    state.rowEditingIdNew = id;
  },
};

const getters = {
  isEdit(state) {
    return !!state.rowEditing;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
