import Api from "./Api";

export default {
  async get() {
    return Api().get("system/getRoles");
  },
  async getCached(cache) {
    return Api().get("system/getRolesCached", { params: { cache } });
  },
  async show(id) {
    return Api().get("system/getRole", { params: { id } });
  },
  async create(role) {
    return Api().post("system/createRole", role);
  },
  async update(role) {
    return Api().put("system/updateRole", role);
  },
  async delete(id) {
    return Api().delete("system/deleteRole", { params: { id } });
  },
};
