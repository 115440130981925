import { getIcon } from "@/utils/IconHelper";
import notificationApi from "../../modules/Notification/api/notificationApi";

const types = {
  1: {
    pageEditName: "TaskEdit",
    pageListName: "Tasks",
    componentListName: "view-Tasks",
  },
  // 2: {
  //   pageEditName: "TaskEdit",
  //   componentListName: "view-Tasks",
  // }, // Проекты
  3: {
    pageEditName: "LocalDocumentEdit",
    pageListName: "LocalDocuments",
    componentListName: "view-LocalDocuments",
  },
  4: {
    pageEditName: "UnwishedEventEdit",
    pageListName: "UnwishedEventsJournal",
    componentListName: "view-UnwishedEvents",
  },
  5: {
    pageEditName: "AuditOperationEdit",
    pageListName: "AuditOperations",
    componentListName: "view-AuditOperations",
  },
  6: {
    pageEditName: "KnowledgeBaseCourseEmployeeEdit",
    pageListName: "KnowledgeBaseMy",
    componentListName: "knowledgebase-my",
  },
  8: {
    pageEditName: "StatisticIndicatorEdit",
    pageListName: "StatisticIndicators",
    componentListName: "view-StatisticIndicators",
  },
};

const state = () => ({
  notificationCounts: [],
  items: {},
});

const mutations = {
  setNotificationCount(state, { count, fullName }) {
    const i = state.notificationCounts.findIndex(
      (e) => e.MenuItem === fullName
    );
    if (i >= 0) state.notificationCounts[i].Count = count;
  },
  setNotificationCounts(state, counts) {
    state.notificationCounts = counts;
  },
  setNotification(state, { fullName, items }) {
    state.items[fullName] = items;
  },
  incrementCount(state, menuItem) {
    const item = state.notificationCounts.find((e) => e.MenuItem === menuItem);
    if (item) {
      item.Count++;
    }
  },
  decrementCount(state, menuItem) {
    const item = state.notificationCounts.find((e) => e.MenuItem === menuItem);
    if (item) {
      item.Count--;
    }
  },
  setRead(state, { item, flag }) {
    const keys = Object.keys(state.items);

    for (let i = 0; i < keys.length; i++) {
      state.items[keys[i]].forEach((element, j) => {
        if (element.Id === item.Id) {
          state.items[keys[i]][j].IsRead = flag;
        }
      });
    }
  },
};

const getters = {
  GET_COUNTS(state) {
    return state.notificationCounts;
  },
  GET_TYPE() {
    return (componentListName) => {
      const keys = Object.keys(types);
      const t = keys.find(
        (e) => types[e].componentListName === componentListName
      );
      return t ? types[t] : null;
    };
  },
};
const actions = {
  async loadNotification({ commit }, { fullName }) {
    const items = (
      await notificationApi.get(fullName === "total" ? null : fullName)
    ).data;

    // if (fullName !== "total")
    //   commit("setNotificationCount", { fullName, count: items.length });
    commit("setNotification", { fullName, items });
    return items;
  },
  async moreNotification({ commit, state }, { fullName }) {
    const name = fullName === "total" ? null : fullName;
    const items = (
      await notificationApi.get(name, state.items[fullName].length)
    ).data;

    commit("setNotification", {
      fullName,
      items: [...state.items[fullName], ...items],
    });
    return items;
  },

  incrementCounts({ commit }, menuList) {
    for (const e of menuList) {
      commit("incrementCount", e);
    }
    commit("incrementCount", "total");
  },
  decrementCounts({ commit }, menuList) {
    for (const e of menuList) {
      commit("decrementCount", e);
    }
    commit("decrementCount", "total");
  },
  getIcon({ _ }, { num, pageType }) {
    return types[num]?.componentListName
      ? getIcon(types[num]?.componentListName, pageType)
      : null;
  },
  async clickNotif({ commit, dispatch }, { notificationItem, router }) {
    const type = types[notificationItem.ObjectType];

    if (notificationItem.CanOpen)
      router.push({
        name: type.pageEditName,
        params: {
          objectId: notificationItem.ObjectId,
        },
      });

    await notificationApi.notificationReceived(notificationItem.Id);
    if (!notificationItem.IsRead)
      // dispatch("decrementCounts", notificationItem.MenuList);
      dispatch("loadNotificationCounts");
    commit("setRead", { item: notificationItem, flag: true });
  },
  async loadNotificationCounts({ commit }) {
    const res = (await notificationApi.getCount()).data;
    commit("setNotificationCounts", res);
  },
  async itemsIsRead({ dispatch }, { fullName }) {
    const response = await notificationApi.allNotificationsReceived(
      fullName === "total" ? null : fullName
    );
    if (response.status === 200) dispatch("loadNotificationCounts");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
