import Api from "./Api";

export default {
  async get() {
    return Api().get("document/getAppliedDocumentTypes");
  },
  async show(id) {
    return Api().get("document/getAppliedDocumentType", { params: { id } });
  },
  async create(type) {
    type.Object = type.Object ?? 0;
    return Api().post("document/createAppliedDocumentType", type);
  },
  async update(type) {
    type.Object = type.Object ?? 0;
    return Api().put("document/updateAppliedDocumentType", type);
  },
  async delete(id) {
    return Api().delete("document/deleteAppliedDocumentType", {
      params: { id },
    });
  },
};
