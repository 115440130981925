import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import { VTreeSelect } from "vuetify-toolkit/vuetify-toolkit.umd";
import ru from "vuetify/es5/locale/ru";
import "@mdi/font/css/materialdesignicons.css";
Vue.use(Vuetify);

// SVG Sprite
const req = require.context("../assets/icons/", true, /\.svg$/);
let svgModules = null;
const requireAll = (requireContext) => {
  svgModules = requireContext.keys().map(requireContext);
  return svgModules;
};
requireAll(req);

const valuesIcon = {};
svgModules.forEach((element) => {
  const words = element.default.id.split("-");
  let nameValuesIcon = "";
  for (let i = 0; i < words.length; i++) {
    if (i === 0) {
      nameValuesIcon += words[i].charAt(0).toUpperCase() + words[i].slice(1);
    } else {
      nameValuesIcon +=
        words[i].charAt(0).toUpperCase() + words[i].slice(1).toLowerCase();
    }
  }

  const namePack = element.default.id.split("-")[0] + "-icon-pack";
  Vue.component(element.default.id, {
    render: function (createElement) {
      return createElement(
        "svg",
        {
          attrs: { "aria-hidden": true },
          class: {
            [namePack]: true,
            [element.default.id]: true,
          },
        },
        [
          createElement("use", {
            attrs: {
              "xlink:href": `#${element.default.id}`,
            },
          }),
        ]
      );
    },
  });

  valuesIcon[nameValuesIcon] = {
    component: element.default.id,
  };
});

export default new Vuetify({
  icons: {
    iconfont: "mdi", // default - only for display purposes
    values: valuesIcon,
  },
  lang: {
    locales: { ru },
    current: "ru",
  },
  VTreeSelect,
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      dark: {
        background: "#F7F7FA",
        primary: "#304FFE",
        secondary: "#b0bec5",
        accent: "#8c9eff",
        error: "#b71c1c",
        "blue-grey": {
          base: "rgb(33, 33, 33)",
          lighten1: "#E1E4F0",
        },
      },
      light: {
        primary: { base: "#2757ff", lighten1: "#5379FB" },
        error: {
          base: "#FB0404",
          lighten1: "#F95050",
          lighten2: "#FC6955",
          lighten3: "#FF8761",
        },
        warning: { base: "#FFC805", lighten1: "#FCB955" },
        success: { base: "#0CA85D", lighten1: "#00C880", lighten2: "#B2EACF" },
        beautify: {
          base: "#00a0a0",
          lighten1: "#86eae3",
        },
        "blue-grey": {
          base: "#5F647B",
          lighten1: "#BBC6F0",
          lighten2: "#E8ECFF",
          lighten3: "#E1E4F0",
          lighten4: "#f5f6ff",
        },
        grey: { base: "#8A90A4", lighten1: "#C6C8D5" },
        white: { base: "#fff", lighten1: "#FBFBFB" },
        background: "#F7F7FA",
        text: {
          base: "#212121",
        },
      },
    },
    // dark: true,
  },
});
