import Api from "../../../services/Api";

export default {
  async get(webMenu, skipCount, topCount = 30) {
    return Api().get("Notification/GetNotificationObjects", {
      params: { webMenu, topCount, skipCount },
    });
  },
  async getCount() {
    return Api().get("Notification/GetNotificationWebMenuCount");
  },
  notificationReceived(Id) {
    return Api().put("Notification/UpdateNotificationReceived", { Id });
  },
  allNotificationsReceived(webMenu) {
    return Api().put("Notification/UpdateAllNotificationsReceived", {
      webMenu,
    });
  },
};
