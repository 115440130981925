import Api from "./Api";

export default {
  async get() {
    return Api().get("unwishedevent/getUnwishedEventResponsibles");
  },
  async getCached(cache) {
    return Api().get("unwishedevent/getUnwishedEventResponsiblesCached", {
      params: { cache },
    });
  },
  async show(id) {
    return Api().get("unwishedevent/getUnwishedEventResponsible", {
      params: { id },
    });
  },
  async create(data) {
    return Api().post("unwishedevent/createUnwishedEventResponsible", data);
  },
  async update(data) {
    return Api().put("unwishedevent/updateUnwishedEventResponsible", data);
  },
  async delete(id) {
    return Api().delete("unwishedevent/deleteUnwishedEventResponsible", {
      params: { id },
    });
  },
};
