/*
  Страницы
*/
import indexOf from "lodash/indexOf";
import some from "lodash/some";
import findIndex from "lodash/findIndex";
import Vue from "vue";
import { localizePageTabs } from "@/data/localization";

const state = () => ({
  // Визуальные
  activeTab: "/",
  isFromNavigation: false,
  tabs: [
    {
      id: 1,
      name: "Home",
      route: "/",
      isUnsaved: false,
    },
  ],
  keyForUpdate: 1,
  // Общение между страницами;
  // func - Списочное->детальное; Функция между двумя любыми страницами - родитель дает функцию дочернему элементу
  // auditOperation - проверека->выполнение проверки
  transaction: {
    listViewDetail: {
      listViewUpdateItems: null,
      listViewInit: null,
    },
    auditOperation: null,
  },
});
const mutations = {
  SET_TRANSACTION_AUDIT_OPERATION(state, auditOperation) {
    state.transaction.auditOperation = auditOperation;
  },
  SET_TRANSACTION_LIST_VIEW_DETAIL(state, listViewDetail) {
    state.transaction.listViewDetail = listViewDetail;
  },
  SET_FROM_NAVIGATION(state, val) {
    state.isFromNavigation = val;
  },
  SET_COMPONENT(state, { index, component, destroy }) {
    state.tabs[index].component = component;
    state.tabs[index].destroy = destroy;
  },
  SET_ACTIVE_TAB(state, value) {
    state.activeTab = value;
  },
  ADD_TABS(state, data) {
    data.newTab.id = Math.round(Math.random() * 100000);
    data.newTab.isUnsaved = false;
    data.newTab.component = null;

    const indexOldTab = state.tabs.findIndex((e) => {
      return e.id === data.oldTab.id;
    });

    if (
      data.newTab.isFromNavigation ||
      indexOldTab === -1 ||
      indexOldTab + 1 === state.tabs.length
    ) {
      state.tabs.push(data.newTab);
    } else {
      state.tabs.splice(indexOldTab + 1, 0, data.newTab);
    }
  },
  REMOVE_TAB(state, tab) {
    const index = state.tabs.indexOf(tab);
    if (index !== -1) state.tabs.splice(index, 1);
  },
  UPDATE_TAB_IS_UNSAVED(state, { route, val }) {
    const tab = state.tabs.find((e) => e.route === route);
    if (tab) tab.isUnsaved = val;
  },
  UPDATE_TAB_OBJECT_TEXT(state, { route, objectText }) {
    const tab = state.tabs.find((e) => e.route === route);
    if (tab) tab.objectText = objectText;
  },
  UPDATE_TAB_PAGE_TYPE(state, { route, pageType }) {
    const tab = state.tabs.find((e) => e.route === route);
    if (tab) tab.pageType = pageType;
  },
  REPLACE_ACTIVE_TAB(state, { data, activeIndex }) {
    if (data?.name) state.tabs[activeIndex].name = data.name;
    if (data?.route) state.tabs[activeIndex].route = data.route;

    state.tabs[activeIndex].id = data.id;
    state.tabs[activeIndex].component = data.id;
  },
  INCREMENT_COMPONENT_KEY(state) {
    state.keyForUpdate = state.keyForUpdate + 1;
  },
  FORCE_BREAK_UNSAVE(state) {
    for (let i = 0; i < state.tabs.length; i++) {
      state.tabs[i].isUnsaved = false;
    }
  },
};

const actions = {
  TAKE_TRANSACTION_VIEW_DETAIL({ commit }) {
    commit("SET_TRANSACTION_LIST_VIEW_DETAIL", null);
  },
  TAKE_TRANSACTION_AUDIT_OPERATION({ commit }) {
    commit("SET_TRANSACTION_AUDIT_OPERATION", null);
  },
  UPDATE_COMPONENT_TAB({ commit }) {
    commit("INCREMENT_COMPONENT_KEY");
  },
  async REGISTER_COMPONENT(
    { commit, getters },
    { component, destroy, isCurrentTab = true }
  ) {
    if (getters.GET_INDEX_CURRENT_TAB > -1 && isCurrentTab)
      commit("SET_COMPONENT", {
        index: getters.GET_INDEX_CURRENT_TAB,
        component,
        destroy,
      });
  },
  async CLOSE_TAB({ commit, state }, { tab, _router }) {
    if (tab.route === "/") return;
    let answer = true;
    if (tab.isUnsaved) {
      if (!tab?.component)
        answer = await Vue.prototype.$confirm(
          "Данные не сохранены! Вы хотите закрыть вкладку?"
        );
      else {
        commit("SET_ACTIVE_TAB", tab.route);
        _router.push(tab.route);
        answer = await tab?.component();
      }
    }
    if (!answer) return;

    const currentIndex = indexOf(state.tabs, tab);
    const newIndexTab = currentIndex === 0 ? 0 : currentIndex - 1;

    if (tab.route === state.activeTab) {
      commit("SET_ACTIVE_TAB", state.tabs[newIndexTab].route);
      _router.push(state.tabs[newIndexTab].route);
    }

    if (tab.destroy) {
      tab.destroy();
    }
    commit("REMOVE_TAB", tab);
  },
  REPLACE_ACTIVE_TAB({ commit, getters }, data) {
    commit("REPLACE_ACTIVE_TAB", {
      data,
      activeIndex: getters.GET_INDEX_CURRENT_TAB,
    });
  },
  REPLACE_TAB({ commit, getters }, { data, activeIndex }) {
    commit("REPLACE_ACTIVE_TAB", {
      data,
      activeIndex: activeIndex,
    });
  },
};

const getters = {
  GET_UNSAVED_PAGES(state) {
    return state.tabs.filter((e) => e.isUnsaved);
  },
  GET_TAB_FULL_NAME() {
    return function (tab) {
      let res = "";

      if (typeof localizePageTabs[tab.name] === "object") {
        const tmp = tab.route.split("/");
        const _type = tab.$route?.params?.type || tmp[tmp.length - 1];
        res = localizePageTabs[tab.name][_type];
      } else res = localizePageTabs[tab.name];

      res += tab.objectText
        ? " " + tab.objectText
        : tab.objectId > 0
        ? " №" + tab.objectId
        : "";

      return res;
    };
  },
  GET_INDEX_CURRENT_TAB(state) {
    const currentIndex = findIndex(state.tabs, (e) => {
      return e.route === state.activeTab;
    });
    return currentIndex;
  },
  IS_LEAST_ONE_UNSAVED_TAB(state) {
    return some(state.tabs, "isUnsaved", true);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
