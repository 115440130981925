import { sortItems } from "vuetify/lib/util/helpers";

function waitForPropertyInObject(
  object,
  propertyName,
  attempts = 5,
  delay = 500
) {
  return new Promise((resolve) => {
    let i = 1;
    const timer = setInterval(() => {
      if (object[propertyName] || i === attempts) {
        resolve();
        clearInterval(timer);
      }
      i++;
    }, delay);
  });
}

// Ожидает появление элемента в дом
function waitForElm(selector) {
  return new Promise((resolve) => {
    if (document.querySelector(selector)) {
      return resolve(document.querySelector(selector));
    }

    const observer = new MutationObserver((mutations) => {
      if (document.querySelector(selector)) {
        resolve(document.querySelector(selector));
        observer.disconnect();
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  });
}

export function getZIndex(el) {
  if (!el || el.nodeType !== Node.ELEMENT_NODE) return 0;
  if (el.classList.contains("v-overlay-select")) return 0;

  const index = +window.getComputedStyle(el).getPropertyValue("z-index");
  const scrim = el.querySelector(".v-overlay__scrim");

  if (!index || scrim.style.opacity === "0") return getZIndex(el.parentNode);
  return index;
}

export function sortBy(items, sortBy, sortDesc, custom) {
  if (sortBy instanceof Function) {
    const stringCollator = new Intl.Collator("en-US", {
      sensitivity: "accent",
      usage: "sort",
    });
    return items.sort((a, b) => {
      let sortA = sortBy(a);
      let sortB = sortBy(b);
      if (sortA === null && sortB === null) {
        return 0;
      }
      if (sortA instanceof Date && sortB instanceof Date) {
        return sortA.getTime() - sortB.getTime();
      }

      [sortA, sortB] = [sortA, sortB].map((s) =>
        (s || "").toString().toLocaleLowerCase()
      );

      if (sortDesc) {
        [sortA, sortB] = [sortB, sortA];
      }

      if (sortA !== sortB) {
        if (!isNaN(sortA) && !isNaN(sortB))
          return Number(sortA) - Number(sortB);
        return stringCollator.compare(sortA, sortB);
      }
      return 0;
    });
  } else return sortItems(items, [sortBy], [sortDesc], "en-US");
}

export function numWord(value, words) {
  const last = value % 10;

  const secondLastDigit = Math.floor(value / 10) % 10;

  if (secondLastDigit !== 1 && last !== 0 && last <= 4) {
    if (last === 1) return ` ${words[0]}:</b>`;
    if (last >= 2 && last <= 4) return ` ${words[1]}:</b>`;
  } else return ` ${words[2]}:</b>`;
}

export function searchTree(element, matching) {
  const childrens = ["Children", "ObjectsSummary"];
  const propertyTree = childrens.find((e) => element[e]) || childrens[0];

  if (matching(element)) {
    return element;
  } else if (element[propertyTree] != null) {
    let i;
    let result = null;
    for (i = 0; result == null && i < element[propertyTree].length; i++) {
      result = searchTree(element[propertyTree][i], matching);
    }
    return result;
  }
  return null;
}

export { waitForElm, waitForPropertyInObject };
