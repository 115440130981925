/*
  Глобальная загрузка
  * Проекты
  * Типы локальных документов
  * Категории обучающих курсов
  * Cязанные организации
  * Все организации
*/

import KnowledgeBaseService from "@/services/KnowledgeBaseService";
import DocumentTemplateService from "@/services/document/DocumentTemplateService";
import LocalDocumentService from "../../services/LocalDocumentService";
import projectService from "../../services/ProjectService";
import organizationService from "../../services/OrganizationService";

import { sortBy } from "@/utils/Functions";

const state = () => ({
  projects: [],
  organizationDocumentTypes: [],
  courseCategories: [],
  relatedOrganizations: [],
  organizations: [],
  documentTemplateAll: [],
});
const mutations = {
  SET_PROJECTS(state, projects) {
    state.projects = projects;
  },
  SET_ORGGANIZATION_DOCUMENT_TYPES(state, organizationDocumentTypes) {
    state.organizationDocumentTypes = organizationDocumentTypes;
  },
  SET_COURSE_CATEGORIES(state, courseCategories) {
    state.courseCategories = courseCategories;
  },
  SET_RELATED_ORGANIZATIONS(state, relatedOrganizations) {
    state.relatedOrganizations = relatedOrganizations;
  },
  SET_ORGANIZATIONS(state, organizations) {
    state.organizations = organizations;
  },
  SET_DOCUMENT_TEMPLATE_ALL(state, templates) {
    state.documentTemplateAll = templates;
  },
};

const actions = {
  async LOAD_RELATED_ORGANIZATIONS({ commit, state }) {
    if (!state.relatedOrganizations.length) {
      const data = (await organizationService.relatedOrganizations.get()).data;
      commit("SET_RELATED_ORGANIZATIONS", sortBy(data, "Name"));
      return data;
    } else return state.relatedOrganizations;
  },
  async LOAD_ORGANIZATIONS({ commit, state }) {
    if (!state.organizations.length) {
      const data = (await organizationService.organizations.get()).data;
      commit("SET_ORGANIZATIONS", sortBy(data, "Name"));
      return data;
    } else return state.organizations;
  },
  async LOAD_PROJECTS({ commit, state }) {
    if (!state.projects.length) {
      const data = (await projectService.get()).data;
      commit("SET_PROJECTS", sortBy(data, "Name"));
      return data;
    } else return state.projects;
  },
  async LOAD_ORGGANIZATION_DOCUMENT_TYPES({ commit, state }) {
    if (!state.organizationDocumentTypes.length) {
      const data = (await LocalDocumentService.getOrganizationDocumentTypes())
        .data;
      commit("SET_ORGGANIZATION_DOCUMENT_TYPES", sortBy(data, "Name"));
      return data;
    } else return state.organizationDocumentTypes;
  },
  async LOAD_COURSE_CATEGORIES({ commit, state }) {
    // if (!state.courseCategories.length) {
    const data = (await KnowledgeBaseService.courseCategories.get()).data;
    commit("SET_COURSE_CATEGORIES", sortBy(data, "Name"));
    return data;
    // } else return state.courseCategories;
  },

  async LOAD_DOCUMENT_TEMPLATE_ALL({ commit, state }) {
    // if (!state.documentTemplateAll.length) {
    const data = (await DocumentTemplateService.get()).data;
    commit("SET_DOCUMENT_TEMPLATE_ALL", sortBy(data, "Name"));
    return data;
    // } else return state.documentTemplateAll;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
