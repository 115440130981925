import IconsData from "../data/IconsData";

const getIcon = (name, type) => {
  if (typeof name === "string" && (type === undefined || type === null)) {
    return typeof IconsData[name] === "object"
      ? IconsData[name].default
      : IconsData[name];
  } else if (typeof name === "string" && typeof type === "string")
    return IconsData[name][type];
};

export { getIcon };
