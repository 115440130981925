import Api from "./Api";

export default {
  async get({ fields = null } = {}) {
    return Api().get("organization/getPartitions", {
      params: { fields },
    });
  },
  async getCached(cache) {
    return Api().get("organization/getPartitionsCached", {
      params: { cache },
    });
  },
  async getByEmployee(employeeId) {
    return Api().get("organization/getEmployeePartitions", {
      params: { employeeId },
    });
  },
  async show(id) {
    return Api().get("organization/getPartition", { params: { id } });
  },
  async create(partition, full = true) {
    return Api().post("organization/createPartition?full=" + full, partition);
  },
  async update(partition, full = true) {
    return Api().put("organization/updatePartition?full=" + full, partition);
  },
  async delete(id) {
    return Api().delete("organization/deletePartition", { params: { id } });
  },
};
