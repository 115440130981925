import Cookies from "js-cookie";
import Vue from "vue";
import Vuex from "vuex";
import frame from "./modules/frame";
import pageTabs from "./modules/pageTabs";
import loaderDataSources from "./modules/loaderDataSources";
import permissions from "./modules/permissions";
import globalOverlay from "./modules/globalOverlay";
import dict from "./modules/dict";
import sessionManager from "./modules/session";
import richWorker from "./modules/richWorker";
import routesData from "./modules/routesData";
import itemEdited from "./modules/itemEdited";
import favoriteTab from "./modules/favoriteTab";
import notification from "./modules/notification";

import { sortBy } from "@/utils/Functions";

import AuditIndicatorService from "../services/AuditIndicatorService";
import AuditIndicatorCategoryService from "@/services/AuditIndicatorCategoryService";
import ContragentService from "../services/ContragentService";
import EmployeesService from "../services/EmployeeService";
import PartitionService from "../services/PartitionService";
import PositionService from "../services/PositionService";
import AppliedDocumentTypeService from "../services/AppliedDocumentTypeService";
import MeasuringTypeService from "../services/MeasuringTypeService";
import RoleService from "../services/RoleService";
import UnwishedEventTypeService from "../services/UnwishedEventTypeService";
import UnwishedConsequenceTypeService from "../services/UnwishedConsequenceTypeService";
import UnwishedEventResponsibleService from "../services/UnwishedEventResponsibleService";
import TaskSettingsService from "../services/TaskSettingsService";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    frame,
    loaderDataSources,
    permissions,
    pageTabs,
    globalOverlay,
    dict,
    sessionManager,
    richWorker,
    routesData,
    itemEdited,
    favoriteTab,
    notification,
  },
  strict: true,
  state: {
    user: localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : null,
    Organization: {
      Name: Cookies.get("OrganizationName")
        ? Cookies.get("OrganizationName")
        : "Название организации",
      OwnerId: Cookies.get("OrganizationOwnerId")
        ? JSON.parse(Cookies.get("OrganizationOwnerId"))
        : null,
      OwnerType: Cookies.get("OrganizationOwnerType")
        ? JSON.parse(Cookies.get("OrganizationOwnerType"))
        : null,
    },
    AccessToken: Cookies.get("AccessToken") || Cookies.get("Token") || "",
    RefreshToken: Cookies.get("RefreshToken") || "",
    auditIndicators: [],
    auditIndicatorsCached: null,
    auditIndicatorCategories: [],
    auditIndicatorCategoriesCached: null,
    contragents: [],
    contragentsCached: null,
    employees: [],
    employeesCached: null,
    partitions: [],
    partitionsCached: null,
    positions: [],
    positionsCached: null,
    appliedDocumentTypes: [],
    measuringTypes: [],
    roles: [],
    rolesCached: null,
    unwishedEventTypes: [],
    unwishedEventTypesCached: null,
    unwishedConsequenceTypes: [],
    unwishedConsequenceTypesCached: null,
    unwishedEventResponsibles: [],
    unwishedEventResponsiblesCached: null,
    taskSettings: null,
  },
  mutations: {
    SET_USER(state, { AccessToken, RefreshToken, user }) {
      state.user = user;
      state.AccessToken = AccessToken;
      state.RefreshToken = RefreshToken;
    },
    SET_PROFILE(state, employee) {
      state.user.Employee = employee;
      if (employee.User) state.user.Picture = employee.User.Picture;
    },
    SET_TOKEN(state, token) {
      state.AccessToken = token;
    },
    SET_REFRESH_TOKEN(state, token) {
      state.RefreshToken = token;
    },
    SET_ORGANIZATION(state, Organization) {
      state.Organization.Name = Organization.Name;
      state.Organization.OwnerId = Organization.OwnerId;
      state.Organization.OwnerType = Organization.OwnerType;
    },
    SET_AUDIT_INDICATORS(state, auditIndicators) {
      state.auditIndicators = auditIndicators;
      state.auditIndicatorsCached = new Date().toISOString();
    },
    SET_AUDIT_INDICATOR_CATEGORIES(state, categories) {
      state.auditIndicatorCategories = categories;
      state.auditIndicatorCategoriesCached = new Date().toISOString();
    },
    SET_CONTRAGENTS(state, contragents) {
      state.contragents = contragents;
      state.contragentsCached = new Date().toISOString();
    },
    SET_EMPLOYEES(state, employees) {
      state.employees = employees;
      state.employeesCached = new Date().toISOString();
    },
    SET_PARTITIONS(state, partitions) {
      state.partitions = partitions;
      state.partitionsCached = new Date().toISOString();
    },
    SET_POSITIONS(state, positions) {
      state.positions = positions;
      state.positionsCached = new Date().toISOString();
    },
    SET_APPLIED_DOCUMENT_TYPES(state, types) {
      state.appliedDocumentTypes = types;
    },
    SET_MEASURING_TYPES(state, types) {
      state.measuringTypes = types;
    },
    SET_ROLES(state, roles) {
      state.roles = roles;
      state.rolesCached = new Date().toISOString();
    },
    SET_UNWISHED_EVENT_TYPES(state, types) {
      state.unwishedEventTypes = types;
      state.unwishedEventTypesCached = new Date().toISOString();
    },
    SET_UNWISHED_CONSEQUENCE_TYPES(state, types) {
      state.unwishedConsequenceTypes = types;
      state.unwishedConsequenceTypesCached = new Date().toISOString();
    },
    SET_UNWISHED_EVENT_RESPONSIBLES(state, responsibles) {
      state.unwishedEventResponsibles = responsibles;
      state.unwishedEventResponsiblesCached = new Date().toISOString();
    },
    SET_TASK_SETTINGS(state, settings) {
      state.taskSettings = settings;
    },
    CONFIRM_PHONE_NUMBER(state, phoneNum) {
      if (phoneNum) {
        state.user.Employee.User.PhoneNum = phoneNum;
        state.user.PhoneNum = phoneNum;
      }
      state.user.Employee.User.IsNumberChangedInformed = true;
      state.user.IsNumberChangedInformed = true;
    },
  },
  actions: {
    async SET_TOKEN({ commit }, AccessToken) {
      commit("SET_TOKEN", AccessToken);
    },
    async SET_REFRESH_TOKEN({ commit }, refreshToken) {
      commit("SET_TOKEN", refreshToken);
    },
    SET_USER({ commit }, { AccessToken, RefreshToken, user }) {
      commit("SET_USER", { AccessToken, RefreshToken, user });
    },
    async LOAD_AUDIT_INDICATORS({ commit, state }) {
      const data = (
        await AuditIndicatorService.getCached(state.auditIndicatorsCached)
      ).data;
      if (!data.Actual)
        commit("SET_AUDIT_INDICATORS", sortBy(data.Data, "Name"));
      return state.auditIndicators;
    },
    async LOAD_AUDIT_INDICATOR_CATEGORIES({ commit, state }) {
      const data = (
        await AuditIndicatorCategoryService.getCached(
          state.auditIndicatorCategoriesCached
        )
      ).data;
      if (!data.Actual)
        commit("SET_AUDIT_INDICATOR_CATEGORIES", sortBy(data.Data, "Name"));
      return state.auditIndicatorCategories;
    },
    async LOAD_CONTRAGENTS({ commit, state }) {
      const data = (await ContragentService.getCached(state.contragentsCached))
        .data;
      if (!data.Actual) commit("SET_CONTRAGENTS", sortBy(data.Data, "Name"));
      return state.contragents;
    },
    async LOAD_EMPLOYEES({ commit, state }) {
      const data = (await EmployeesService.getCached(state.employeesCached))
        .data;
      if (!data.Actual) commit("SET_EMPLOYEES", data.Data);
      return state.employees;
    },
    async LOAD_PARTITIONS({ commit, state }) {
      const data = (await PartitionService.getCached(state.partitionsCached))
        .data;
      if (!data.Actual) commit("SET_PARTITIONS", sortBy(data.Data, "Name"));
      return state.partitions;
    },
    async LOAD_POSITIONS({ commit, state }) {
      const data = (await PositionService.getCached(state.positionsCached))
        .data;
      if (!data.Actual) commit("SET_POSITIONS", sortBy(data.Data, "Name"));
      return state.positions;
    },
    async LOAD_APPLIED_DOCUMENT_TYPES({ commit, state }) {
      if (!state.appliedDocumentTypes.length) {
        const data = (await AppliedDocumentTypeService.get()).data;
        commit("SET_APPLIED_DOCUMENT_TYPES", sortBy(data, "Name"));
        return data;
      } else return state.appliedDocumentTypes;
    },
    async LOAD_MEASURING_TYPES({ commit, state }) {
      if (!state.measuringTypes.length) {
        const data = (await MeasuringTypeService.get()).data;
        commit("SET_MEASURING_TYPES", data);
        return data;
      } else return state.measuringTypes;
    },
    async LOAD_ROLES({ commit, state }) {
      const data = (await RoleService.getCached(state.rolesCached)).data;
      if (!data.Actual) commit("SET_ROLES", data.Data);
      return state.roles;
    },
    async LOAD_UNWISHED_EVENT_TYPES({ commit, state }) {
      const data = (
        await UnwishedEventTypeService.getCached(state.unwishedEventTypesCached)
      ).data;
      if (!data.Actual)
        commit("SET_UNWISHED_EVENT_TYPES", sortBy(data.Data, "Name"));
      return state.unwishedEventTypes;
    },
    async LOAD_UNWISHED_CONSEQUENCE_TYPES({ commit, state }) {
      const data = (
        await UnwishedConsequenceTypeService.getCached(
          state.unwishedConsequenceTypesCached
        )
      ).data;
      if (!data.Actual)
        commit(
          "SET_UNWISHED_CONSEQUENCE_TYPES",
          sortBy(data.Data, "Description")
        );
      return state.unwishedConsequenceTypes;
    },
    async LOAD_UNWISHED_EVENT_RESPONSIBLES({ commit, state }) {
      const data = (
        await UnwishedEventResponsibleService.getCached(
          state.UnwishedEventResponsibleCached
        )
      ).data;
      if (!data.Actual) commit("SET_UNWISHED_EVENT_RESPONSIBLES", data.Data);
      return state.unwishedEventResponsibles;
    },
    async LOAD_TASK_SETTINGS({ commit, state }) {
      if (!state.taskSettings) {
        const data = (await TaskSettingsService.show()).data;
        commit("SET_TASK_SETTINGS", data);
        return data;
      } else return state.taskSettings;
    },
  },
  getters: {
    GET_USER(state) {
      return state.user;
    },
    GET_ORGANIZATION(state) {
      return state.Organization;
    },
    IS_AUTH(state) {
      if (state.AccessToken && state.user) return true;
      else return false;
    },
  },
});
