// import { isEqual } from "lodash";
const state = () => ({
  items: [],
});

const mutations = {
  SET_COMPONENT(state, { componentName, params, callback, meta }) {
    state.items.push({
      id: Math.round(Math.random() * 10000000),
      componentName,
      params: { ...params },
      meta: { ...meta },
      object: {},
      callback: callback ?? null,
      dialog: false,
      reset: false,
    });
  },
  CLEAR_COMPONENTS(state, id = null) {
    if (id !== null) {
      state.items = state.items.filter((e) => e.id !== id);
    } else {
      state.items = [];
    }
  },
  SET_DIALOG(state, { show, index }) {
    state.items[index].dialog = show;
  },

  SET_OBJECT(state, { obj, id }) {
    const index = id
      ? state.items.findIndex((e) => e.id === id)
      : state.items.length - 1;
    state.items[index].object = obj;

    if (state.items[index].callback) state.items[index].callback();
  },
  // Дополняет мета данные
  SET_META(state, { meta, id }) {
    const index = id
      ? state.items.findIndex((e) => e.id === id)
      : state.items.length - 1;
    if (state.items[index])
      state.items[index].meta = state.items[index].meta
        ? { ...state.items[index].meta, ...meta }
        : meta;
  },
  SET_RESET(state, { reset, id }) {
    state.reset = reset;
  },
};

const actions = {
  SHOW_DIALOG({ commit, state, dispatch }, { show, id }) {
    const index = id
      ? state.items.findIndex((e) => e.id === id)
      : state.items.length - 1;
    commit("SET_DIALOG", { show, index });
    if (!show /* && state.items[index].params?.closeDestoroy */) {
      dispatch("DESTROY_COMPONENT", id);
    }
  },
  /*
    meta - нужна для передачи любых данных в компонент
  */
  RUN_COMPONENT({ commit, state }, { componentName, params, callback, meta }) {
    // if (state.componentName === componentName && isEqual(state.params, params))
    //  commit("SET_DIALOG", true);
    // else
    commit("SET_COMPONENT", { componentName, params, callback, meta });
  },
  DESTROY_COMPONENT({ commit, state }, id = null) {
    let _id = id;
    if (id === null && state.items.length > 0) {
      _id = state.items[state.items.length - 1].id;
    }
    commit("CLEAR_COMPONENTS", _id);

    // commit("SET_RESET", true);
  },
};

const getters = {
  GET_DATA(state) {
    return (id) =>
      id
        ? state.items.find((e) => e.id === id)
        : state.items[state.items.length - 1];
  },
  GET_COMPONENT_NAME(state) {
    return state.componentName;
  },
  GET_PARAMS(state) {
    return state.params;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
