import Api from "./Api";

export default {
  async show() {
    return Api().get("task/GetTaskSettings");
  },
  async update(data) {
    return Api().put("task/UpdateTaskSettings", data);
  },
};
