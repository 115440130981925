import Api from "./Api";

export default {
  async get(
    onlyUserData = true,
    onlyWithCheckLists = false,
    checkListCount = false
  ) {
    return Api().get("auditOperation/getAuditIndicators", {
      params: { onlyUserData, onlyWithCheckLists, checkListCount },
    });
  },
  async getCached(cache) {
    return Api().get("auditOperation/getAuditIndicatorsCached", {
      params: { cache },
    });
  },
  async show(id) {
    return Api().get("auditOperation/getAuditIndicator", { params: { id } });
  },
  async create(indicator) {
    return Api().post("auditOperation/createAuditIndicator", indicator);
  },
  async createOnTemplates(templateIds) {
    return Api().post("auditOperation/createAuditIndicatorsOnTemplates", {
      Ids: templateIds,
    });
  },
  async update(indicator) {
    return Api().put("auditOperation/updateAuditIndicator", indicator);
  },
  async delete(id) {
    return Api().delete("auditOperation/deleteAuditIndicator", {
      params: { id },
    });
  },
};
