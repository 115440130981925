import Api from "./Api";

export default {
  async get() {
    return Api().get("unwishedevent/getUnwishedEventTypes?full=true");
  },
  async getCached(cache) {
    return Api().get("unwishedevent/getUnwishedEventTypesCached", {
      params: { cache },
    });
  },
  async show(id) {
    return Api().get("unwishedevent/getUnwishedEventType", { params: { id } });
  },
  async create(type) {
    return Api().post("unwishedevent/createUnwishedEventType", type);
  },
  async update(type) {
    return Api().put("unwishedevent/updateUnwishedEventType", type);
  },
  async delete(id) {
    return Api().delete("unwishedevent/deleteUnwishedEventType", {
      params: { id },
    });
  },
};
