import Api from "./Api";

export default {
  async show() {
    return Api().get("organization/GetCurrentOrganization");
  },
  async update(data) {
    return Api().put("organization/UpdateCurrentOrganization", data);
  },
  organizations: {
    get() {
      return Api().get("organization/GetAllOrganizations");
    },
  },
  relatedOrganizations: {
    get() {
      return Api().get("organization/GetRelatedOrganizations");
    },
  },
};
