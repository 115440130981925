// import dictionary from "@/dict";
const state = () => ({
  dict: null,
});

const actions = {
  START_DICT({ commit, dispatch }, dict) {
    commit("SET_DICT", dict);

    dispatch(
      "frame/RUN_COMPONENT",
      {
        componentName: dict.componentEdit,
        params: {
          _objectId: 0,
          _type: "create",
          _created: true,
          persistent: true,
          closeDestoroy: true,
        },
        callback: () => {
          dispatch("frame/SHOW_DIALOG", { show: false }, { root: true });
        },
      },
      { root: true }
    );
  },
};
const mutations = {
  SET_DICT(state, dict) {
    state.dict = dict;
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
