import Api from "./Api";

export default {
  async get() {
    return Api().get("unwishedevent/getUnwishedConsequenceTypes");
  },
  async getCached(cache) {
    return Api().get("unwishedevent/getUnwishedConsequenceTypesCached", {
      params: { cache },
    });
  },
  async show(id) {
    return Api().get("unwishedevent/getUnwishedConsequenceType", {
      params: { id },
    });
  },
  async create(type) {
    return Api().post("unwishedevent/createUnwishedConsequenceType", type);
  },
  async update(type) {
    return Api().put("unwishedevent/updateUnwishedConsequenceType", type);
  },
  async delete(id) {
    return Api().delete("unwishedevent/deleteUnwishedConsequenceType", {
      params: { id },
    });
  },
};
