const Permissions = {
  // Редактирование реквизитов организации
  OrganizationEdit: 100,

  // Редактирование подразделений
  PartitionsEdit: 120,

  // Редактирование сотрудников
  EmployeesEdit: 140,

  // Редактирование пользователей
  UsersEdit: 160,
  // Редактирование ФИО профиля
  UserProfileFIOEdit: 161,
  // Редактирование должности и рабочего места сотрудника
  UserProfileWorkPlacesEdit: 162,

  // Редактирование чек-листов
  CheckListsEdit: 180,

  // Редактирование всех мед. изделий
  EquipmentsEditAll: 200,
  // Просмотр всех мед. изделий
  EquipmentsViewAll: 201,
  // Редактирование мед. изделий своего подразделения
  EquipmentsEdit: 202,

  // Редактирование контрагентов
  ContragentsEdit: 220,

  // Редактирование настроек
  SettingsEdit: 240,

  // Редактирование нормативных документов
  NormativeDocumentsEdit: 260,

  // Редактирование локальных документов
  OrganizationDocumentsEdit: 280,
  // Перевод локального документа в состояние "Действующий"
  OrganizationDocumentActive: 281,
  // Перевод локального документа в состояние "Отменен" и назад в "Действующий"
  OrganizationDocumentCancel: 282,
  // Редактирование даты, названия и номера документа
  OrganizationDocumentNumDateEdit: 283,
  // Редактирование всех локальных документов
  OrganizationAllDocumentsEdit: 285,

  // Просмотр всех документов
  OrganizationDocumentViewAll: 290,

  // Редактирование шаблонов документов
  DocumentTemplatesEdit: 300,

  // Разрешает редактирование образцов (всех действий)
  DocumentSampleEdit: 310,

  // Редактирование проверок
  AuditOperationsEdit: 320,
  // Редактирование проверок
  AuditOperationsEditHowAuditor: 321,
  // Просмотр всех операций аудита (проверок)
  AuditOperationViewAll: 322,

  // Редактирование задач
  TasksEdit: 340,
  // Редактирование всех задач
  AllTasksEdit: 341,

  // Редактирование мероприятий (проекты)
  ProjectsEdit: 360,
  // Редактирование всех проектов
  AllProjectsEdit: 361,

  // Редактирование показателей качества
  AuditIndicatorsEdit: 380,
  // Редактирование категорий показателей качества
  AuditIndicatorCategoriesEdit: 390,

  // Редактирование должностей
  PositionsEdit: 400,

  // Редактирование типов прикладных документов
  AppliedDocumentTypesEdit: 420,

  // Редактирование ролей
  RolesEdit: 440,

  // Редактирование нежелательных событий
  UnwishedEventEdit: 460,
  // Редактирование типов нежелательных событий
  UnwishedEventTypesEdit: 470,
  // Редактирование типов последствий нежелательных событий
  UnwishedConsequenceTypesEdit: 471,
  // Редактирование ответственных за результаты нежелательных событий
  UnwishedEventResponsibleEdit: 472,

  // Просмотр показателей и их значений
  StatisticIndicatorView: 481,
  // Редактирование показателей и их значений
  StatisticIndicatorEdit: 480,

  // Редактирование курсов
  KnowledgeBaseCourseEdit: 500,
  // Редактирование всех курсов обучения
  KnowledgeBaseAllCourseEdit: 503,
  // Просмотр всех курсов обучения
  KnowledgeBaseAllCourseReady: 504,

  /// Просмотр всех сводных отчётов
  SummaryReportViewAll: 720,
  /// Редактирование сводных отчётов
  SummaryReportEdit: 721,
  /// Редактирование всех сводных отчётов
  SummaryReportEditAll: 722,
  /// Просмотр раздела "Сводный отчёт"
  SummaryReportViewSection: 723,

  /// ТФОМС
  /// Все собственники
  OwnerViewAll: 800,
  /// Просмотр раздела статистических данных ТФОМС
  TFOMSView: 820,
};

export default Permissions;
