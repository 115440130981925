import { localizePageTabs, localizeReportNames } from "@/data/localization";

import { cloneDeep } from "lodash";

const state = {
  favoriteTab: Object.values(
    JSON.parse(localStorage.getItem("Favorite")) || {}
  ),
};

const mutations = {
  UPDATE_FAVORITE_TAB(state, favoriteTab) {
    state.favoriteTab = favoriteTab.filter((e) => e.isFavorite);
  },
};

const actions = {
  SET_LOCAL_STORAGE(_, data) {
    localStorage.setItem(
      "Favorite",
      JSON.stringify(data.filter((e) => e.isFavorite))
    );
  },

  TOGGLE_FAVORITE({ getters, commit, dispatch }, tab) {
    const arr = cloneDeep(getters.GET_FAVORITE_TAB);
    const index = arr.findIndex((e) => e.route === tab.route);

    if (index > -1) {
      arr[index].isFavorite = !arr[index].isFavorite;
    } else if (tab.name === "ReportPage") {
      arr.push({
        route: tab.route,
        text: localizeReportNames[tab.$route.params.report],
        typeView: tab.pageType,
        view: tab.nameComponent,
        isFavorite: true,
      });
    } else {
      arr.push({
        route: tab.route,
        text:
          tab.pageType &&
          tab.pageType !== "undefined" &&
          tab.pageType !== "plans" &&
          tab.pageType !== "projects"
            ? localizePageTabs[tab.name][tab.pageType] +
              (tab.objectText ? ": " + tab.objectText : "")
            : localizePageTabs[tab.name] +
              (tab.objectText || tab.objectId > 0 ? ": " : "") +
              (tab.objectText
                ? tab.objectText
                : tab.objectId > 0
                ? "№" + tab.objectId
                : ""),
        typeView: tab.pageType,
        view: tab.nameComponent,
        isFavorite: true,
      });
    }

    commit("UPDATE_FAVORITE_TAB", arr);
    dispatch("SET_LOCAL_STORAGE", arr);
  },
};

const getters = {
  GET_FAVORITE_TAB: (state) => state.favoriteTab,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
