import Api from "./Api";
import store from "../store";
import qs from "qs";

export default {
  testEmployee: {
    async show(id) {
      return Api().get("knowledgebase/GetTestEmployee", {
        params: { testId: id },
      });
    },
    async update(test) {
      return Api().put("knowledgebase/UpdateTestEmployee", test);
    },
    start(id, restart) {
      return Api().put("knowledgebase/StartTestEmployee", {
        id,
        restart,
      });
    },
    execute(id, QuestionResults) {
      return Api().put("knowledgebase/ExecuteTestEmployee", {
        TestId: id,
        QuestionAnswers: QuestionResults,
      });
    },
  },
  test: {
    async show(id) {
      return Api().get("knowledgebase/GetTest", {
        params: { id },
      });
    },
    async update(test) {
      return Api().put("knowledgebase/UpdateTest", test);
    },
  },
  material: {
    async show(id) {
      return Api().get("knowledgebase/GetMaterial", {
        params: { id, rtf: true },
      });
    },
    async update(material) {
      return Api().put("knowledgebase/UpdateMaterial", material);
    },
    async UpdateLessonMaterialFile(id, file) {
      const formData = new FormData();
      formData.append("Id", id);
      formData.append("File", file);
      return Api({ "Content-Type": "multipart/form-data" }).put(
        "knowledgebase/UpdateMaterialFile",
        formData
      );
    },
    async appointMaterial(params) {
      return Api().put("knowledgebase/AppointMaterial", params);
    },
    getByLesson(id) {
      return Api().get("knowledgebase/GetMaterials?lessonId=" + id);
    },
  },
  materialEmployee: {
    async show(id) {
      return Api().get("knowledgebase/GetMaterialEmployee", {
        params: { materialId: id },
      });
    },
    async update(material) {
      return Api().put("knowledgebase/UpdateLessonMaterial", material);
    },

    execute(id) {
      return Api().put("knowledgebase/ExecuteLessonMaterialEmployee", { id });
    },
  },
  lesson: {
    get(id) {
      return Api().get("knowledgebase/GetLessons?courseId=" + id);
    },
  },
  course: {
    async get(params = { done: null, fields: 1 }) {
      return Api().get("knowledgebase/GetCourses", {
        params: {
          done: params.done ?? null,
          fields: typeof fields === "undefined" ? 1 : params.fields,
        },
        paramsSerializer: function (params) {
          return qs.stringify(params);
        },
      });
    },
    async show(id) {
      return Api().get("knowledgebase/GetCourse", { params: { id } });
    },
    async create(knowledgebase) {
      return Api().post("knowledgebase/createcourse", knowledgebase);
    },
    async update(knowledgebase) {
      return Api().put("knowledgebase/UpdateCourse", knowledgebase);
    },
    async delete(id) {
      return Api().delete("knowledgebase/DeleteCourse", { params: { id } });
    },
  },
  courseTemplate: {
    async get(params) {
      const p = { full: true, ...params };
      return Api().get("knowledgebase/GetCourseTemplates?full=" + p.full);
    },
    async show(id) {
      return Api().get("knowledgebase/GetCourseTemplate", { params: { id } });
    },
    async create(knowledgebase) {
      return Api().post("knowledgebase/CreateCourseTemplate ", knowledgebase);
    },
    async update(knowledgebase) {
      return Api().put("knowledgebase/UpdateCourseTemplate", knowledgebase);
    },
    async delete(id) {
      return Api().delete("knowledgebase/DeleteCourseTemplate", {
        params: { id },
      });
    },
  },
  сourseEmployee: {
    async get(full = true) {
      return Api().get("knowledgebase/GetCourseEmployees", {
        params: {
          full,
        },
      });
    },
    async show(courseId, employeeId) {
      const _employeeId = employeeId || store.getters.GET_USER.Employee.Id;
      return Api().get("knowledgebase/GetCourseEmployee", {
        params: { courseId, employeeId: _employeeId, rtf: true },
      });
    },
    async create(knowledgebase) {
      return Api().post("knowledgebase/CreateCourseEmployee", knowledgebase);
    },
    async delete(id) {
      return Api().delete("knowledgebase/DeleteCourseEmployee", {
        params: { id },
      });
    },
  },
  courseCategories: {
    async get() {
      return Api().get("knowledgebase/GetCourseSubjects");
    },
    async create(courseCategories) {
      return Api().post("knowledgebase/CreateCourseSubject", courseCategories);
    },
    async update(courseCategories) {
      return Api().put("knowledgebase/UpdateCourseSubject", courseCategories);
    },
    async delete(id) {
      return Api().delete("knowledgebase/DeleteCourseSubject", {
        params: { id },
      });
    },
  },
};
