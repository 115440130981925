import Cookies from "js-cookie";

const state = () => ({
  userPermissions: new Set(
    Cookies.get("Permissions") ? JSON.parse(Cookies.get("Permissions")) : []
  ),
});

const mutations = {
  SET_PERMISSIONS(state, list) {
    state.userPermissions = new Set(list);
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
};
