import Api from "./Api";

export default {
  async get({ type = null, doneTaskPercent = false, fields = 0 } = {}) {
    return Api().get("task/GetProjects", {
      params: { type, doneTaskPercent, fields },
    });
  },
  async show(id) {
    return Api().get("task/GetProject", { params: { id } });
  },
  async create(project) {
    return Api().post("task/CreateProject", project);
  },
  async update(project) {
    return Api().put("task/UpdateProject", project);
  },
  async delete(id) {
    return Api().delete("task/DeleteProject", { params: { id } });
  },
};
