const state = () => ({
  value: false,
  overlayForInput: false,
  tryCloseOverlayForInput: false,
});

const mutations = {
  SET(state, value) {
    state.value = value;
  },
  SET_OVERLAY_INPUT(state, value) {
    state.overlayForInput = value;
    if (!value) {
      state.tryCloseOverlayForInput = false;
    }
  },
  SET_TRY_OVERLAY_INPUT(state, value) {
    state.tryCloseOverlayForInput = value;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
