const state = () => ({
  spellCheckerWorker: null,
});

const mutations = {
  SET_WORKER(state, spellCheckerWorker) {
    if (!state.spellCheckerWorker) {
      state.spellCheckerWorker = spellCheckerWorker;
    }
  },
};

const actions = {};
const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
