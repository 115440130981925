import Api from "./Api";

export default {
  async get() {
    return Api().get("common/GetActivitySections");
  },
  async show(id) {
    return Api().get("common/GetActivitySection", { params: { id } });
  },
  async getCached(cache) {
    return Api().get("common/GetActivitySectionsCached", {
      params: { cache },
    });
  },
  async create(category) {
    return Api().post("common/CreateActivitySection", category);
  },
  async update(category) {
    return Api().put("common/UpdateActivitySection", category);
  },
  async delete(id) {
    return Api().delete("common/DeleteActivitySection", {
      params: { id },
    });
  },
};
