import Api from "./Api";

export default {
  async get(active) {
    return Api().get("organization/getPositions", { params: { active } });
  },
  async show(id) {
    return Api().get("organization/getPosition", { params: { id } });
  },
  async getByPartition(partitionId) {
    return Api().get("organization/GetPartitionPositions", {
      params: { partitionId, full: true },
    });
  },
  async getCached(cache) {
    return Api().get("organization/getPositionsCached", { params: { cache } });
  },
  async create(position) {
    return Api().post("organization/createPosition", position);
  },
  async update(position) {
    return Api().put("organization/updatePosition", position);
  },
  async delete(id) {
    return Api().delete("organization/deletePosition", { params: { id } });
  },
};
