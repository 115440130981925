import Api from "./Api";

export default {
  async get(inActive = true, full = true) {
    return Api().get("organization/getEmployees", {
      params: { inActive, full },
    });
  },
  async getCached(cache, inActive = true) {
    return Api().get("organization/getEmployeesCached", {
      params: { cache, inActive },
    });
  },
  async show(id) {
    return Api().get("organization/getEmployee", { params: { id } });
  },
  async create(employee) {
    return Api().post("organization/createEmployee", employee);
  },
  async update(employee) {
    return Api().put("organization/updateEmployee", employee);
  },
  async delete(id) {
    return Api().delete("organization/deleteEmployee", { params: { id } });
  },
  async updatePassword(id) {
    return Api().put("organization/updateEmployeePassword", { Id: id });
  },
  async updateMultiple(roleIds, objectIds) {
    return Api().put("organization/updateEmployees", {
      RoleIds: roleIds,
      ObjectIds: objectIds,
    });
  },
};
