<template>
  <v-dialog
    content-class="popup"
    v-model="dialog"
    transition="dialog-bottom-transition"
    max-width="1500"
    scrollable
    :width="width"
    :persistent="persistent"
  >
    <template v-slot:activator="scope">
      <slot name="activator" v-bind="scope" />
    </template>
    <template
      v-for="name in Object.keys($scopedSlots).filter(
        (slot) => slot !== 'default' && slot !== 'activator'
      )"
      :slot="name"
      slot-scope="slotData"
      ><slot :name="name" v-bind="slotData"
    /></template>
    <v-card :height="height" :color="color">
      <v-toolbar
        v-if="!headerHidden"
        elevation="0"
        style="border-bottom: solid 1px var(--v-blue-grey-lighten3)"
      >
        <v-toolbar-title class="title-wrap">
          <m-icon
            v-if="!(icon === null) && icon !== undefined"
            class="mr-4"
            :icon="icon"
            active
          ></m-icon>
          {{ title }}
          <slot name="subTitle"></slot>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <slot name="actions"></slot>
        <template v-if="!onlyClose">
          <v-btn
            @click="
              dialog = false;
              $emit('cancel');
            "
            outlined
            color="blue-grey"
          >
            Отмена
          </v-btn>
          <v-btn
            @click="$emit('save')"
            v-if="!hideSave"
            :disabled="disabledSave"
            dark
            color="primary"
            class="ml-4"
          >
            <m-icon icon="tool-save" left size="18"></m-icon>
            {{ buttonSaveText }}
          </v-btn>
        </template>
        <template v-else>
          <v-btn @click="dialog = false" dark color="primary" class="ml-4">
            Закрыть
          </v-btn>
        </template>
        <v-progress-linear
          :active="loadingFile"
          :indeterminate="loadingFile"
          absolute
          bottom
          height="4"
        ></v-progress-linear>
      </v-toolbar>
      <v-card-text class="popup__content">
        <slot></slot>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    headerHidden: Boolean,
    title: String,
    value: Boolean,
    hideSave: Boolean,
    disabledSave: Boolean,
    width: String,
    height: String,
    buttonSaveText: {
      type: String,
      default: "Сохранить",
    },
    loadingFile: {
      type: Boolean,
      default: false,
    },
    persistent: {
      type: Boolean,
      default: true,
    },
    onlyClose: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: null,
    },
    color: {
      type: String,
      default: "#FFFFFF",
    },
  },
  data: () => {
    return {
      dialog: false,
    };
  },
  watch: {
    dialog: function (val) {
      this.$emit("input", val);
    },
    value: {
      immediate: true,
      handler: function (val) {
        this.dialog = val;
      },
    },
  },
};
</script>

<style lang="scss">
.title-wrap {
  display: flex;
  align-items: center;
}

.popup {
  contain: layout;
  &__content {
    height: 100%;
  }
}
</style>
