import Permissions from "../../data/permissions";

export default {
  namespaced: true,
  state: {
    fixed: JSON.parse(localStorage.getItem("is-fixed-navigation")) ?? true,
    isHover: false,
    routesData: [],
  },

  getters: {
    GET_ROUTES_DATA(state) {
      return state.routesData;
    },
    GET_IS_FIXED(state) {
      return state.fixed;
    },
    GET_IS_HOVER(state) {
      return state.isHover;
    },
  },

  mutations: {
    SET_ITEMS(state, items) {
      state.routesData = items;
    },
    SET_FIXED(state, newVal) {
      localStorage.setItem("is-fixed-navigation", newVal);
      state.fixed = newVal;
    },
    SET_IS_HOVER(state, newVal) {
      state.isHover = newVal;
    },
  },
  actions: {
    INIT_ROUTES_DATA(context) {
      // Динамические элементы навигации
      const docViewAll = context.rootState.permissions.userPermissions.has(
        Permissions.OrganizationDocumentViewAll
      )
        ? [
            {
              text: "Все документы",
              view: "view-LocalDocuments",
              typeView: "all",
              route: "/documents/local/all",
            },
          ]
        : [];
      const AuditOperationAll =
        context.rootState.permissions.userPermissions.has(
          Permissions.AuditOperationViewAll
        )
          ? [
              {
                text: "Все проверки",
                view: "view-AuditOperations",
                typeView: "all",
                route: "/control/checks/view/all",
              },
            ]
          : [];

      const consolidatedeport =
        context.rootState.permissions.userPermissions.has(
          Permissions.SummaryReportViewSection
        )
          ? [
              {
                text: "Сводный отчет",
                view: "view-ConsolidatedReportEdit",
                route: "/reports/consolidated/view",
              },
            ]
          : [];

      const tfoms = context.rootState.permissions.userPermissions.has(
        Permissions.TFOMSView
      )
        ? [
            {
              text: "ТФОМС",
              view: "view-AnalysisTform",
              route: "/analysis/tfoms",
            },
          ]
        : [];

      const kibmd =
        context.rootState.Organization.OwnerType === 2
          ? [
              {
                text: "Анализ МО",
                view: "view-AnalysisIndicators",
                route: "/analysis/kibmd",
              },
            ]
          : [];

      const organizations =
        context.rootState.Organization.OwnerType !== 0
          ? [
              {
                text: "Список организаций",
                view: "view-Organizations",
                route: "/organization/organizations",
              },
            ]
          : [];

      const AuditOperationExternal =
        context.rootState.Organization.OwnerType !== 0
          ? [
              {
                text: "Внешние проверки",
                view: "view-AuditOperations",
                typeView: "external",
                route: "/control/checks/view/external",
              },
            ]
          : [];

      const itemsList = [
        {
          text: "Главная",
          view: "view-Home",
          route: "/",
        },
        {
          text: "Документы",
          view: "view-LocalDocuments",
          children: [
            ...docViewAll,
            {
              text: "Мои документы",
              view: "view-LocalDocuments",
              typeView: "my",
              route: "/documents/local/my",
            },
            {
              text: "Черновики",
              view: "view-LocalDocuments",
              typeView: "developing",
              route: "/documents/local/developing",
            },
            {
              text: "Шаблоны документов",
              view: "view-DocumentTemplates",
              route: "/documents/templates/view/template",
            },
            {
              text: "Образцы документов",
              view: "view-DocumentSample",
              route: "/documents/templates/view/sample",
            },
          ],
        },
        {
          text: "Обучение",
          view: "knowledgebase-my",
          children: [
            {
              text: "Все курсы",
              view: "knowledgebase-all",
              route: "/education/courses/all",
            },
            {
              text: "Мои курсы",
              view: "knowledgebase-my",
              route: "/education/courses/my",
            },
            {
              text: "Базовые курсы",
              view: "view-knowledgebase",
              route: "/education/courses/base/view",
            },
            {
              text: "Тематики курсов",
              view: "view-CourseTopics",
              route: "/education/courses/topics",
            },
          ],
        },
        {
          text: "Проверки",
          view: "view-AuditOperations",
          children: [
            ...AuditOperationAll,
            {
              text: "Мои проверки",
              view: "view-AuditOperations",
              typeView: "my",
              route: "/control/checks/view/my",
            },
            ...AuditOperationExternal,
            {
              text: "Чек-листы",
              view: "view-CheckLists",
              route: "/control/check-lists/view",
            },
            {
              text: "Показатели качества",
              view: "view-AuditIndicators",
              route: "/control/indicators/view",
            },
          ],
        },
        {
          text: "Нежелательные события",
          view: "view-UnwishedEvents",
          children: [
            {
              text: "Журнал НС",
              view: "view-UnwishedEvents",
              route: "/unwished-events/journal",
            },
            {
              text: "Виды НС",
              view: "view-UnwishedEventTypes",
              route: "/unwished-events/types/view",
            },
            {
              text: "Последствия НС",
              view: "view-UnwishedConsequenceTypes",
              route: "/unwished-events/consequence",
            },
            {
              text: "Ответств. за рез-ты НС",
              view: "view-UnwishedEventResponsibles",
              route: "/unwished-events/responsibles/view",
            },
          ],
        },
        {
          text: "Статистические данные",
          view: "view-StatisticIndicators",
          route: "/statistics/view",
        },
        {
          text: "Медицинские изделия",
          view: "view-Equipments",
          route: "/equipments/view",
        },
        {
          text: "Анализ",
          view: "view-AnalysisIndicators",
          children: [
            {
              text: "Анализ",
              view: "view-AnalysisIndicators",
              route: "/analysis/view",
            },
            ...tfoms,
            ...kibmd,
          ],
        },
        {
          text: "Задачи",
          view: "view-Tasks",
          typeView: null,
          children: [
            {
              text: "Все задачи",
              view: "view-Tasks",
              typeView: "all",
              route: "/tasks/view/all",
            },
            {
              text: "Мои задачи",
              view: "view-Tasks",
              typeView: "my",
              route: "/tasks/view/my",
            },
            {
              text: "Задачи на контроле",
              view: "view-Tasks",
              typeView: "control",
              route: "/tasks/view/control",
            },
          ],
        },
        {
          text: "Планы и проекты",
          view: "view-Projects",
          typeView: "all",
          children: [
            {
              text: "Планы",
              view: "view-Projects",
              typeView: "plans",
              route: "/activity/plans/view",
            },
            {
              text: "Проекты",
              view: "view-Projects",
              typeView: "projects",
              route: "/activity/projects/view",
            },
          ],
        },
        {
          text: "Отчеты",
          view: "MoreReports",
          children: [
            {
              text: "Отчеты",
              view: "MoreReports",
              route: "/reports/view",
            },
            ...consolidatedeport,
          ],
        },
        {
          text: "Организация",
          view: "Organization",
          children: [
            {
              text: "Реквизиты",
              view: "view-OrganizationEdit",
              route: "/organization/requisites/edit",
            },
            ...organizations,
            {
              text: "Подразделения",
              view: "view-Partitions",
              route: "/organization/divisions/view",
            },
            {
              text: "Должности",
              view: "view-Positions",
              route: "/organization/position/view",
            },
            {
              text: "Сотрудники",
              view: "view-Employees",
              route: "/organization/employees/view",
            },
            {
              text: "Стандарты оснащения",
              view: "view-EquipmentStandarts",
              route: "/organization/equipment-standarts/view",
            },
            {
              text: "Разделы деятельности",
              view: "view-AuditIndicatorCategories",
              route: "/organization/activity-sections/view",
            },
            // {
            //   text: "Шабны чек-листов",
            //   view: "o-checkl",
            //   route: "/checklisttemplates",
            // },
            // {
            //   text: "Шаблоны пок. качества",
            //   view: "d-quality",
            //   route: "/auditindicatortemplates",
            // },
            {
              text: "Контрагенты",
              view: "view-Contragents",
              route: "/organization/contragents/view",
            },
            {
              text: "Прикладные документы",
              view: "view-AppliedDocumentTypes",
              route: "/organization/applied-document-types",
            },
            // {
            //   text: "Настройки отчетов",
            //   view: "o-reportsset",
            //   route: "/reportsettings",
            // },
            // {
            //   text: "Начало работы",
            //   view: "view-Instructions",
            //   route: "/instructions",
            // },
          ],
        },
      ];

      context.commit("SET_ITEMS", itemsList);
    },
  },
};
