import Api from "./Api";

export default {
  async get() {
    return Api().get("organization/getContragents");
  },
  async getCached(cache) {
    return Api().get("organization/getContragentsCached", {
      params: { cache },
    });
  },
  async show(id) {
    return Api().get("organization/getContragent", { params: { id } });
  },
  async create(contragent) {
    return Api().post("organization/createContragent", contragent);
  },
  async update(contragent) {
    return Api().put("organization/updateContragent", contragent);
  },
  async delete(id) {
    return Api().delete("organization/deleteContragent", { params: { id } });
  },
};
